.content {
  background-color: white;
  padding: 1em 2em;
}

.container {
  margin-top: 60px;
  background-color: white;
  padding: 0;
  min-height: calc(100vh - 60px);
}
