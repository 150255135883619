@import '~/styles/colors.scss';

.publicHeader {
  display: flex;
  align-items: center;
  background: url('/images/header-bg-gradient.png'),
    url('/images/header-bg-gradient-map.png'), var(--primary);
  background-size: cover, cover;
  height: 100px;
  padding: 0 50px;
  color: white;

  h1 {
    font-size: 29px;
    color: white;
  }

  h1,
  p {
    margin: 0;
  }

  p {
    font-weight: 300;
    font-size: 17px;
  }
}

@media (max-width: 640px) {
  .publicHeader {
    padding: 10px;

    h1 {
      font-size: 20px;
    }
  }
}
