@import '~/styles/colors.scss';

.footer {
  padding: 30px 0;
  background-color: #eceeef;
  border-top: 2px solid var(--hiago-primary);
  color: #c6d0dd;
  font-size: 0.95em;
  :global(a) {
    color: #626d7a;
  }
  :global(.row > div:not(:first-child)) {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  :global(.row > div:last-child) {
    color: #626d7a;
    a {
      color: #147494;
    }
  }
}
