.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);
}

@media (max-width: 768px) {
  .top {
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .content {
    padding: 2em 2.5em;
  }
}
