.card {
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #d3dce6;
  box-sizing: border-box;
  box-shadow: 1px 2px 10px rgba(212, 212, 211, 0.59);
  border-radius: 8px;

  &:hover {
    filter: brightness(1.02);
  }
}

.logo {
  width: auto;
  height: 40px;
}

.img {
  max-width: 290px;
  height: 50px;
}
